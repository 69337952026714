.App {
  text-align: center;
}

.App-header {
  align-items: center;
  background-color: #f8f4f0;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 64px;
  justify-content: center;
  min-height: 100vh;
}